@import "rsuite/dist/rsuite.css";

@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 500 600;
    src: url('App/assets/fonts/als-sirius/ALSSirius-Medium.woff2') format("woff2"), url('App/assets/fonts/als-sirius/ALSSirius-Medium.woff') format("woff")
}

@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 400;
    src: url('App/assets/fonts/als-sirius/ALSSirius-Regular.woff2') format("woff2"), url('App/assets/fonts/als-sirius/ALSSirius-Regular.woff') format("woff")
}

@font-face {
    font-display: swap;
    font-family: ALS Sirius;
    font-style: normal;
    font-weight: 300;
    src: url('App/assets/fonts/als-sirius/ALSSirius-Light.woff2') format("woff2"), url('App/assets/fonts/als-sirius/ALSSirius-Light.woff') format("woff")
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #434242;
}

/* Стили для ячейки в диапазоне дат */
.rs-calendar-table-cell-in-range::before {
    background-color: #616161;
}

/* Стили для ячейки, соответствующей сегодняшней дате */
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #616161, inset 0 0 0 1px #434242;
    box-shadow: inset 0 0 0 1px #616161, inset 0 0 0 1px #434242;
}

/* Стили при наведении на ячейку */
.rs-calendar-table-cell-content:hover,
.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: #616161;
}

/* Стили для основной кнопки */
.rs-btn-primary {
    background-color: #434242;
}

.rs-btn-primary:hover {
    background-color: #616161;
}

.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
    background-color: #616161;
}

/* Скрытие панели выбора диапазона дат */
.rs-picker-popup .rs-picker-toolbar-ranges {
    display: none;
}

/* Стили для группы ввода */
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
    border-color: white;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
    outline: none;
}

.rs-input-group {
    border: none;
}

/* Стили для увеличенной группы ввода */
.rs-input-group-lg.rs-input-group > .rs-input {
    height: 45px;
}

/* Стили для диапазона дат */
.rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input,
.rs-picker-daterange > .rs-input-group.rs-input-group-inside {
    border-radius: 12px;
    font-family: ALS Sirius;
    font-weight: 500;
    font-size: 18px;
    color: #9B9B9B;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}

.rs-picker-toggle-wrapper {
    width: 100%;
}
.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    color: #434242;
}